import anime from 'animejs/lib/anime'

window.$ = window.jQuery = require('jquery');
window.Popper = require('@popperjs/core');
require('bootstrap');

const mainMenuId = '#main-menu';

function closeMenu(e) {
    if (e.target !== this)
        return;

    if (screen.width <= 576) {
        anime({
            targets: mainMenuId,
            translateX: '-100%',
            easing: 'easeInOutQuad',
            duration: 700,
        });
    }
}

window.showMenu = function () {
    if (screen.width <= 576) {
        $(mainMenuId).css('transform', 'translateX(-100%)');

        anime({
            targets: mainMenuId,
            translateX: '0%',
            easing: 'easeInOutQuad',
            duration: 700,
        });
    }
}

function activeMostSimilarUrlLink() {
    // Ativa link referente à página atual
    let urlPath = window.location.pathname
    let search = urlPath.split('/')[1];

    let $activeLink;
    if (search) {
        $activeLink = $(`${mainMenuId} li a[href*="${window.location.origin}/${search}"]`)
    } else {
        $activeLink = $(`${mainMenuId} li a[href="${window.location.origin}"]`)
    }
    
    $activeLink.addClass('opacity-75')
}

$(function () {
    $(mainMenuId).on('click', closeMenu);
    activeMostSimilarUrlLink()
});
